import * as R from 'ramda'

export const keyToLayerStyle = key => {
  switch (key) {
    case 'background-color' : return 'paint'
    case 'background-opacity' : return 'paint'
    case 'background-pattern' : return 'paint'
    case 'circle-blur' : return 'paint'
    case 'circle-color' : return 'paint'
    case 'circle-opacity' : return 'paint'
    case 'circle-pitch-alignment' : return 'paint'
    case 'circle-pitch-scale' : return 'paint'
    case 'circle-radius' : return 'paint'
    case 'circle-sort-key' : return 'layout'
    case 'circle-stroke-color' : return 'paint'
    case 'circle-stroke-opacity' : return 'paint'
    case 'circle-stroke-width' : return 'paint'
    case 'circle-translate' : return 'paint'
    case 'circle-translate-anchor' : return 'paint'
    case 'fill-antialias' : return 'paint'
    case 'fill-color' : return 'paint'
    case 'fill-extrusion-base' : return 'paint'
    case 'fill-extrusion-color' : return 'paint'
    case 'fill-extrusion-height' : return 'paint'
    case 'fill-extrusion-opacity' : return 'paint'
    case 'fill-extrusion-pattern' : return 'paint'
    case 'fill-extrusion-translate' : return 'paint'
    case 'fill-extrusion-translate-anchor' : return 'paint'
    case 'fill-extrusion-vertical-gradient' : return 'paint'
    case 'fill-opacity' : return 'paint'
    case 'fill-outline-color' : return 'paint'
    case 'fill-pattern' : return 'paint'
    case 'fill-sort-key' : return 'layout'
    case 'fill-translate' : return 'paint'
    case 'fill-translate-anchor' : return 'paint'
    case 'heatmap-color' : return 'paint'
    case 'heatmap-intensity' : return 'paint'
    case 'heatmap-opacity' : return 'paint'
    case 'heatmap-radius' : return 'paint'
    case 'heatmap-weight' : return 'paint'
    case 'hillshade-accent-color' : return 'paint'
    case 'hillshade-exaggeration' : return 'paint'
    case 'hillshade-highlight-color' : return 'paint'
    case 'hillshade-illumination-anchor' : return 'paint'
    case 'hillshade-illumination-direction' : return 'paint'
    case 'hillshade-shadow-color' : return 'paint'
    case 'icon-allow-overlap' : return 'layout'
    case 'icon-anchor' : return 'layout'
    case 'icon-color' : return 'paint'
    case 'icon-halo-blur' : return 'paint'
    case 'icon-halo-color' : return 'paint'
    case 'icon-halo-width' : return 'paint'
    case 'icon-ignore-placement' : return 'layout'
    case 'icon-image' : return 'layout'
    case 'icon-keep-upright' : return 'layout'
    case 'icon-offset' : return 'layout'
    case 'icon-opacity' : return 'paint'
    case 'icon-optional' : return 'layout'
    case 'icon-padding' : return 'layout'
    case 'icon-pitch-alignment' : return 'layout'
    case 'icon-rotate' : return 'layout'
    case 'icon-rotation-alignment' : return 'layout'
    case 'icon-size' : return 'layout'
    case 'icon-text-fit' : return 'layout'
    case 'icon-text-fit-padding' : return 'layout'
    case 'icon-translate' : return 'paint'
    case 'icon-translate-anchor' : return 'paint'
    case 'line-blur' : return 'paint'
    case 'line-cap' : return 'layout'
    case 'line-color' : return 'paint'
    case 'line-dasharray' : return 'paint'
    case 'line-gap-width' : return 'paint'
    case 'line-gradient' : return 'paint'
    case 'line-join' : return 'layout'
    case 'line-miter-limit' : return 'layout'
    case 'line-offset' : return 'paint'
    case 'line-opacity' : return 'paint'
    case 'line-pattern' : return 'paint'
    case 'line-round-limit' : return 'layout'
    case 'line-sort-key' : return 'layout'
    case 'line-translate' : return 'paint'
    case 'line-translate-anchor' : return 'paint'
    case 'line-width' : return 'paint'
    case 'raster-brightness-max' : return 'paint'
    case 'raster-brightness-min' : return 'paint'
    case 'raster-contrast' : return 'paint'
    case 'raster-fade-duration' : return 'paint'
    case 'raster-hue-rotate' : return 'paint'
    case 'raster-opacity' : return 'paint'
    case 'raster-resampling' : return 'paint'
    case 'raster-saturation' : return 'paint'
    case 'symbol-avoid-edges' : return 'layout'
    case 'symbol-placement' : return 'layout'
    case 'symbol-sort-key' : return 'layout'
    case 'symbol-spacing' : return 'layout'
    case 'symbol-z-order' : return 'layout'
    case 'text-allow-overlap' : return 'layout'
    case 'text-anchor' : return 'layout'
    case 'text-color' : return 'paint'
    case 'text-field' : return 'layout'
    case 'text-font' : return 'layout'
    case 'text-halo-blur' : return 'paint'
    case 'text-halo-color' : return 'paint'
    case 'text-halo-width' : return 'paint'
    case 'text-ignore-placement' : return 'layout'
    case 'text-justify' : return 'layout'
    case 'text-keep-upright' : return 'layout'
    case 'text-letter-spacing' : return 'layout'
    case 'text-line-height' : return 'layout'
    case 'text-max-angle' : return 'layout'
    case 'text-max-width' : return 'layout'
    case 'text-offset' : return 'layout'
    case 'text-opacity' : return 'paint'
    case 'text-optional' : return 'layout'
    case 'text-padding' : return 'layout'
    case 'text-pitch-alignment' : return 'layout'
    case 'text-radial-offset' : return 'layout'
    case 'text-rotate' : return 'layout'
    case 'text-rotation-alignment' : return 'layout'
    case 'text-size' : return 'layout'
    case 'text-transform' : return 'layout'
    case 'text-translate' : return 'paint'
    case 'text-translate-anchor' : return 'paint'
    case 'text-variable-anchor' : return 'layout'
    case 'visibility' : return 'layout'
    default : return 'paint'
  }
}

export const createMapFeature = (features) => {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    }
  }
}

export const setSource = (sourceId, features, map) => {
  map.getSource(sourceId).setData({
    type: 'FeatureCollection',
    features: features
  })
}

export const convertRadiusToZoom = (radius, venueCenter, map, renderDiv = '#renderDiv') => {
  const mercatorR = 6378137
  const zoomToRadiusConstant = Math.cos(venueCenter.lat * Math.PI / 180.0) * Math.PI * mercatorR / 256.0
  const padding = map.getPadding()
  const verticalPadding = padding.top + padding.bottom
  const horizontalPadding = padding.left + padding.right
  const renderElement = document.querySelector(renderDiv)
  const points = Math.min(renderElement.offsetWidth - horizontalPadding, renderElement.offsetHeight - verticalPadding) * 0.5
  const metersPerPoint = radius / points
  return Math.log2(zoomToRadiusConstant / metersPerPoint)
}

export const convertZoomToRadius = (zoom, venueCenter, map, renderDiv = '#renderDiv') => {
  const mercatorR = 6378137
  const zoomToRadiusConstant = Math.cos(venueCenter.lat * Math.PI / 180.0) * Math.PI * mercatorR / 256.0
  const padding = map.getPadding()
  const verticalPadding = padding.top + padding.bottom
  const horizontalPadding = padding.left + padding.right
  const renderElement = document.querySelector(renderDiv)
  const points = Math.min(renderElement.offsetWidth - horizontalPadding, renderElement.offsetHeight - verticalPadding) * 0.5
  const metersPerPoint = zoomToRadiusConstant / Math.pow(2, zoom)
  return points * metersPerPoint
}

export const getMapState = map => {
  if (!map.getCenter)
    throw Error('***Error in utils:172 - map.getCenter not a function!')
  const { lat, lng } = map.getCenter()
  return { bearing: map.getBearing(), lng, lat, pitch: map.getPitch(), zoom: map.getZoom() }
}

export const getClosedPois = (event) => R.pipe(
  R.prop('idValuesMap'),
  R.filter(R.complement(R.prop('isOpen'))),
  R.keys
)(event)

// list of colors that are visibly distinct from white and from each other
// useful for debug tools in outlining items with clear distinction
export const variousColors = [
  '#FF0000', '#FF8C00', '#FFD700', '#ADFF2F', '#32CD32', '#006400',
  '#20B2AA', '#4682B4', '#1E90FF', '#0000FF', '#00008B', '#9400D3',
  '#FF1493', '#C71585', '#DC143C', '#800000', '#8B4513', '#CD853F',
  '#DAA520', '#556B2F', '#2E8B57', '#5F9EA0', '#483D8B', '#4B0082'
]
